import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M5226 10079 c-708 -40 -1439 -256 -2056 -606 -278 -158 -598 -389
-835 -603 -91 -82 -311 -304 -376 -379 -35 -41 -69 -79 -74 -85 -16 -17 -177
-225 -213 -276 -18 -25 -48 -65 -66 -90 -18 -25 -60 -88 -93 -141 -365 -581
-593 -1190 -697 -1864 -63 -407 -58 -1080 10 -1445 50 -271 80 -399 133 -585
24 -86 117 -355 140 -410 10 -22 33 -76 52 -120 52 -123 172 -363 246 -493 60
-106 257 -410 272 -422 4 -3 18 -21 31 -40 31 -45 168 -216 240 -299 139 -162
480 -487 620 -591 18 -14 56 -43 84 -66 113 -90 384 -266 566 -368 158 -89
441 -220 610 -284 399 -150 824 -250 1220 -287 706 -65 1352 10 2010 233 249
85 548 217 742 329 35 20 87 49 117 65 47 26 180 109 251 158 133 90 366 265
406 304 6 6 40 35 75 65 83 71 358 347 429 431 30 36 57 67 60 70 3 3 25 30
50 60 25 30 47 57 50 60 3 3 17 21 30 40 13 19 27 37 30 40 3 3 17 21 30 40
13 19 27 37 30 40 21 15 224 334 295 459 308 550 488 1105 582 1796 26 193 26
863 0 1055 -117 862 -360 1503 -835 2202 -40 59 -79 113 -97 132 -5 6 -30 38
-55 71 -24 33 -52 69 -61 80 -10 11 -26 29 -36 41 -10 12 -43 50 -73 85 -105
123 -351 363 -510 499 -56 47 -69 58 -160 126 -432 321 -725 486 -1190 667
-616 241 -1294 346 -1984 306z m1018 -1625 c23 -9 20 -46 -7 -96 -70 -127
-175 -326 -220 -413 -8 -16 -25 -50 -38 -75 -29 -57 -45 -89 -58 -120 -6 -14
-29 -56 -50 -95 -22 -38 -65 -121 -96 -182 -31 -62 -66 -120 -78 -128 -19 -13
-145 -15 -1024 -15 l-1002 0 -56 -26 c-55 -26 -95 -64 -95 -90 0 -8 -4 -14 -9
-14 -11 0 -31 -87 -31 -135 0 -49 44 -162 76 -196 15 -16 51 -38 80 -49 51
-18 90 -20 806 -26 568 -5 763 -9 793 -19 22 -7 60 -16 85 -19 43 -7 163 -44
225 -71 17 -7 37 -16 45 -19 8 -3 22 -10 30 -15 8 -5 22 -12 30 -15 8 -4 29
-15 45 -26 17 -10 44 -26 62 -35 17 -9 39 -25 49 -36 9 -10 23 -19 31 -19 16
0 211 -192 243 -240 13 -19 27 -37 31 -40 3 -3 18 -27 33 -55 15 -27 34 -63
44 -80 29 -50 82 -176 82 -197 0 -10 6 -31 14 -46 13 -27 26 -91 45 -232 6
-47 12 -667 16 -1662 l6 -1588 -590 0 c-528 0 -589 2 -595 16 -3 9 -6 744 -6
1635 l0 1620 -29 57 c-32 63 -76 102 -143 129 -39 16 -114 18 -808 23 -660 5
-773 8 -820 22 -30 9 -77 21 -105 27 -57 13 -167 57 -269 108 -67 34 -176 99
-186 112 -3 4 -20 18 -39 31 -170 118 -378 450 -407 648 -4 26 -13 61 -22 77
-23 46 -24 482 0 545 9 25 19 63 23 85 17 100 83 252 163 373 23 36 42 67 42
69 0 6 89 101 129 137 19 17 42 38 51 46 86 79 288 200 334 200 8 0 17 4 20 9
15 23 240 85 371 101 72 9 2756 13 2779 4z m811 -1794 c22 -6 85 -15 140 -20
116 -11 201 -25 265 -45 25 -7 86 -25 135 -40 50 -14 109 -35 133 -46 23 -10
48 -19 54 -19 18 0 298 -143 358 -184 51 -34 127 -90 217 -161 119 -94 296
-298 398 -459 39 -62 165 -307 165 -322 1 -5 9 -27 20 -49 11 -22 19 -49 20
-60 0 -11 6 -29 13 -40 8 -11 26 -69 40 -130 52 -226 57 -273 57 -565 0 -286
-5 -338 -51 -530 -12 -47 -25 -102 -29 -123 -4 -21 -13 -44 -19 -50 -6 -6 -11
-19 -11 -30 0 -55 -180 -416 -263 -527 -183 -244 -373 -417 -617 -562 -106
-63 -294 -152 -385 -182 -306 -102 -583 -150 -836 -144 l-124 3 -3 568 -2 567
127 0 c132 0 296 24 383 55 185 66 356 186 455 319 59 80 145 251 145 288 0
13 5 29 11 35 16 16 38 204 38 318 0 108 -18 250 -42 330 -42 145 -159 330
-274 436 -97 88 -316 197 -445 219 -29 5 -127 12 -218 15 -91 4 -175 8 -187
11 -34 7 -50 49 -57 153 -7 101 -39 245 -81 368 -56 164 -184 388 -282 494
-20 21 -41 50 -49 64 -11 22 -11 28 4 43 16 16 49 17 387 14 209 -1 387 -6
410 -12z m-2267 -1366 l22 -15 0 -537 c0 -470 -2 -540 -16 -560 l-15 -22
-1193 0 c-1079 0 -1194 2 -1200 16 -10 27 -7 74 9 104 8 16 22 50 31 77 9 26
29 75 45 109 16 34 29 67 29 74 0 7 10 34 21 59 12 25 25 55 30 66 49 123 64
159 85 215 14 36 31 79 38 95 28 60 85 201 92 225 3 14 18 44 31 68 l25 42
972 0 c863 0 974 -2 994 -16z m-375 -3163 c24 -9 27 -16 27 -56 0 -55 -12 -57
-37 -8 -22 45 -42 58 -77 49 -24 -6 -27 -11 -24 -39 3 -27 13 -39 70 -77 85
-56 107 -94 86 -148 -8 -21 -29 -47 -48 -60 -30 -20 -44 -23 -100 -20 -108 6
-143 35 -126 104 9 33 21 30 36 -7 20 -47 49 -69 92 -69 26 0 40 5 47 19 14
25 -12 58 -79 101 -56 36 -80 72 -80 122 0 88 106 132 213 89z m331 -148 c34
-84 70 -160 79 -171 32 -35 22 -42 -63 -42 -81 0 -97 9 -69 37 9 9 9 20 -1 42
-12 30 -15 31 -70 31 -55 0 -58 -1 -70 -31 -10 -22 -10 -33 -1 -42 24 -24 12
-37 -33 -37 -49 0 -67 14 -42 33 8 7 35 66 60 132 54 147 61 171 47 179 -27
17 -7 27 45 24 l56 -3 62 -152z m276 148 c0 -5 -7 -14 -15 -21 -13 -11 -15
-37 -13 -154 l3 -141 35 2 c59 3 79 12 95 43 9 17 20 28 26 24 13 -7 0 -96
-15 -106 -19 -13 -264 -9 -272 4 -4 6 -2 14 4 16 15 5 17 308 2 317 -27 16 -3
25 70 25 44 0 80 -4 80 -9z m340 -91 c19 -55 38 -100 41 -100 4 0 22 44 41 98
l34 97 57 3 c61 3 63 2 46 -27 -17 -25 34 -289 58 -306 34 -25 41 -15 102 145
49 128 56 156 42 164 -25 16 -8 26 42 26 61 0 49 14 112 -140 59 -142 70 -166
88 -187 30 -34 13 -44 -69 -42 -72 2 -89 10 -64 35 6 6 6 21 -2 42 -12 31 -14
32 -70 32 -55 0 -59 -2 -69 -27 -7 -20 -7 -33 0 -45 6 -10 11 -22 11 -26 0 -5
-61 -10 -135 -10 -110 -1 -135 1 -135 13 0 7 5 17 11 21 11 7 7 52 -13 148
-12 58 -14 56 -58 -76 -30 -90 -52 -120 -71 -101 -9 9 -68 164 -76 201 -10 42
-19 22 -32 -68 -10 -70 -10 -94 -1 -105 18 -22 3 -35 -41 -35 -41 0 -49 12
-25 39 18 21 50 231 43 287 l-5 44 52 0 52 0 34 -100z m903 -12 l87 -111 0 96
c0 53 -4 99 -10 102 -23 14 -7 25 35 25 48 0 52 -4 30 -34 -12 -16 -15 -54
-15 -177 l0 -158 -27 3 c-16 2 -31 4 -34 5 -4 0 -26 27 -50 59 -23 31 -45 59
-49 62 -3 3 -21 25 -40 50 -41 53 -56 70 -64 70 -3 0 -6 -44 -6 -97 0 -74 4
-100 16 -114 22 -24 10 -39 -31 -39 -37 0 -54 15 -36 33 14 14 15 303 1 312
-24 15 -6 25 48 25 l57 -1 88 -111z m579 80 c32 -19 52 -41 67 -71 49 -100 8
-198 -102 -245 -39 -17 -246 -17 -229 0 16 16 17 328 1 328 -5 0 -7 5 -3 11 4
7 42 10 113 7 94 -3 111 -6 153 -30z m314 -33 c13 -33 28 -69 33 -80 6 -11 24
-55 41 -97 17 -42 38 -82 46 -89 27 -22 14 -29 -61 -29 -43 0 -75 4 -75 10 0
6 5 10 10 10 14 0 12 30 -3 59 -11 21 -19 24 -71 24 -61 -1 -71 -7 -83 -52 -4
-15 -2 -26 5 -29 28 -9 8 -22 -33 -22 -25 0 -45 5 -45 10 0 6 6 10 13 10 15 0
30 33 77 165 17 50 36 99 40 111 4 11 4 27 -1 35 -16 25 -10 30 37 27 l46 -3
24 -60z m430 40 c2 -11 6 -35 9 -52 7 -38 -11 -46 -20 -10 -7 27 -59 61 -81
53 -11 -4 -14 -33 -14 -150 0 -86 4 -147 10 -151 26 -16 4 -25 -60 -25 -64 0
-86 9 -60 25 12 7 14 291 2 298 -20 13 -61 -7 -80 -41 l-22 -37 0 51 c0 28 3
54 7 58 4 3 74 5 156 4 141 -3 150 -4 153 -23z m191 13 c5 -7 35 -76 67 -153
32 -77 64 -150 72 -162 8 -12 14 -25 14 -28 0 -3 -31 -5 -70 -5 -69 0 -81 5
-59 27 7 7 6 20 -4 44 -14 34 -15 34 -73 32 -32 -1 -62 -2 -65 -3 -14 0 -20
-72 -8 -86 10 -12 4 -14 -35 -14 -48 0 -55 5 -33 24 13 11 25 37 70 156 43
111 53 153 39 158 -7 2 -12 8 -12 13 0 14 86 11 97 -3z"
          />
          <path
            d="M4596 1973 c-9 -26 -16 -48 -16 -50 0 -2 18 -3 39 -3 l40 0 -16 45
c-8 25 -19 47 -23 50 -4 3 -15 -16 -24 -42z"
          />
          <path
            d="M5795 1977 c-10 -26 -15 -50 -12 -53 3 -3 21 -4 40 -2 l34 3 -21 50
-22 50 -19 -48z"
          />
          <path
            d="M6697 2103 c-4 -3 -7 -69 -7 -145 0 -113 3 -140 15 -144 26 -10 69
12 93 46 30 45 31 150 2 198 -25 40 -83 66 -103 45z"
          />
          <path
            d="M7056 1998 c-32 -78 -32 -78 19 -78 43 0 46 2 39 22 -12 39 -34 88
-40 88 -3 0 -11 -15 -18 -32z"
          />
          <path
            d="M7697 1983 c-9 -27 -17 -51 -17 -55 0 -5 18 -8 40 -8 25 0 40 5 40
12 0 17 -34 98 -41 98 -3 0 -13 -21 -22 -47z"
          />{' '}
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
